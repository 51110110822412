<template>
  <div class="surveyCategoryList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增分类" @click="create"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="分类名称" v-model="searchParams.value"></v-input>
        <v-select label="状态" v-model="searchParams.codeFlag" :options="statusOps"></v-select>
        <v-datepicker label="创建时间" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime" type="rangedatetimer"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" v-if="(scope.row.value !== '活动调查')&&(((getUserInfo.userType=='101'||getUserInfo.userType=='102')&&scope.row.hierarchy==1)||(getUserInfo.userType=='106')||(getUserInfo.userType=='100'))"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL } from './api'
import { statusOps, statusMap } from './map'

export default {
  name: 'surveyCategoryList',
  data () {
    return {
      searchUrl: getListURL,
      statusOps: statusOps,
      searchParams: {
        value: '',
        codeFlag: undefined,
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'value',
          label: '分类名称'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.codeFlag]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  computed: {
    getUserInfo () {
      return this.$store.state.userInfo
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'surveyCategoryForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'surveyCategoryForm',
        query: {
          id: row.id
        }
      })
    },
    goBack () {
      this.$router.push({
        name: 'satisfactionRateSurvey1'
      })
    }
  }
}
</script>
